// eslint-disable-next-line @typescript-eslint/no-var-requires
const routes = require('./fos_js_routes.json');
// eslint-disable-next-line @typescript-eslint/no-var-requires,import/extensions
const Routing = require('../../api/vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js');

function getBaseUrl() {
  const url = window.location.href;
  const mainUrlParts = url.split('//')[1];
  const v3UrlWithPort = mainUrlParts.split('/')[0];
  const v3UrlWithoutPort = v3UrlWithPort.split(':')[0];
  const v2UrlWithoutPort = v3UrlWithoutPort.replace('-v3', '');

  const port = process.env.DOCKER_PORT;

  return v2UrlWithoutPort + (port ? `:${port}` : '');
}

Routing.setRoutingData(routes);
Routing.setHost(getBaseUrl());

window.Routing = Routing;
